import React from 'react'
import { AccordionBody, AccordionItem, AccordionWrapper, HeaderWrapper, IconWraper,AccordionContainer } from './Accordion.styled';
import {FaAngleUp, FaAngleDown, FaTwitter} from 'react-icons/fa'
import { useState } from 'react';
import { accordionData } from '../../pages/Career/carrerData';


const AccordionItems = ({handleSelectdItem}) =>{  
  const [open, setOpen] = useState(false)
  const [currentAccordion, setCurrentAccordion] = useState(false);
  const [currentId, setCurrentId] = useState(undefined);


  // const handleClick = (id) => {
  //   setCurrentAccordion(!currentAccordion)
  //   setCurrentId(id)
  //   handleSelectdItem(id)
  // }

  const toogle = index => {
    if(currentId === index){
      return setCurrentId(null)
    }
    setCurrentId(index)
  }


  return(

    // <AccordionContainer>
    //  { accordionData.map((item, index) => (
    //   <AccordionWrapper key={index} >
    //   <AccordionItem >
    //       <HeaderWrapper onClick={() => handleClick(item.id)} >
    //         <h1 >{item.title}</h1>
    //         <IconWraper >
    //           {currentAccordion ?   <FaAngleUp /> : <FaAngleDown /> }
    //         </IconWraper>
    //       </HeaderWrapper >
    //   <AccordionBody open={currentAccordion} >
    //     <p>{item.content}</p>
    //   </AccordionBody>
    //   </AccordionItem>
    // </AccordionWrapper>
  
    //   ))}
    // </AccordionContainer>

    <AccordionContainer>
     { accordionData.map((item, index) => (
      <AccordionWrapper key={index} >
      <AccordionItem >
          <HeaderWrapper onClick={() => toogle(index)} >
            <h2 >{item.title}</h2>
            <IconWraper >
              {currentId === index ?   <FaAngleUp /> : <FaAngleDown /> }
            </IconWraper>
          </HeaderWrapper >
      <AccordionBody open={currentId === index} >
        <p>{item.content}</p>
      </AccordionBody>
      </AccordionItem>
    </AccordionWrapper>
  
      ))}
    </AccordionContainer>

  
  
  )
}

export default  AccordionItems
  
