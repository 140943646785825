import React from 'react'
import { Container } from '../../globalStyle'
import SocialIcons from '../Footer/SocialIcons'
import { useLocation } from 'react-router-dom';




const SuccessPage = ({route}) => {
  const { state } = useLocation()



  // console.log(location.state.error);

  return(
    <Container center={"center"} success={true} >
      <h1>
        {state.from === "contact" ? 
        "Thank you for contacting us here at Meta5Team.\n We will get back to you as soon as we can" :
        "Your application was send successfully."
        }
        
      </h1><br />
      <SocialIcons success={true}  />
    </Container>
  
  )
}

export default  SuccessPage
  
