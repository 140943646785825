import styled from "styled-components";

export const  FormSection = styled.div`
width: 100%;
max-width: 1000px;
display: flex;
flex-direction: row;
margin: 80px auto;
border-radius: 4px;
box-shadow: ${({type}) => type === "file" ? "none" : "0 0 10px rgba(0,0,0,0.15)"};
    -webkit-appearance: none;    

    @media (max-width: 992px) {
        display: flex;
        flex-direction: column;
        margin: 0px auto;
        margin-bottom: 20px;
        
    }

`

export const  InfoBanner = styled.div`
width: 100%;
max-width: 1000px;
display: flex;
justify-content: center;
align-items: center;
background-color: ${({theme: { color:{textDark}}}) => textDark};
color: ${({theme: { color:{white}}}) => white};
border-radius: 4px 80px 80px 4px;
box-shadow: ${({type}) => type === "file" ? "none" : "0 0 10px rgba(0,0,0,0.15)"};
    -webkit-appearance: none;    

ul{
    list-style: none;
    display: flex;
    flex-direction: column;
    height: 50%;
    justify-content: space-around;
    margin: 0 auto;
}
li{
    display: flex;

    div{
        padding: 0 20px;
    }

    @media (max-width: 992px) {
        font-size: 12px;
        margin-bottom: 30px;

        
    }
}

@media (max-width: 992px) {
    padding: 20px 0;
    border-radius: 0px 0px 20px 20px;

        
    }

`
export const  FormWrapper = styled.div`
width: 100%;
max-width: 1000px;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
margin: 40px auto;

h1{
    font-weight: 400;
}

`

export const Forms = styled.form`
    display: flex;
    flex-direction: column;
    height: fit-content;
    width: 100%;
    margin: 0 auto;
    padding: 40px;


    @media screen and (max-width: 992px) {
        height: fit-content;
    }

`


export const FormGroup = styled.div`
    text-align: start;
	color: ${({theme: { color:{primary}}}) => primary};
    display: block;
	width: 300px;
	margin: 4px auto;
    position: relative;
    margin-bottom: ${({type}) => type === "file" && "4.5em"};

    button{
        position: absolute;
        right:  0;
        left: ${({type}) => type === "file" && 0};
    }
`;

export const Label = styled.label`
	/* margin-bottom: 0.5em; */
	color: ${({theme: { color:{textDark}}}) => textDark};
    display: block;
    margin-bottom: 4px;
    font-size: 12px;
`;


export const InputTextArea = styled.textarea`
	padding: 0.8em;
    color: ${({type, theme: {color}}) => type !== "file" ? "#333" : color.primary};
    height: ${({name}) => name === "message" && "150px"};
	background:  #fff;
	border: none;
	border-radius: 4px;
	width: 100%;
	margin-bottom: 0.5em;
    box-shadow: ${({type}) => type === "file" ? "none" : "0 0 10px rgba(92,204,156,0.25)"};
    -webkit-appearance: none;    
    
    &:focus {
        border: "none";

    }

`;


export const Input = styled.input`
	padding: 0.8em;
    color: ${({type, theme: {color}}) => type !== "file" ? "#333" : color.primary};
	background:  #fff;
	border: none;
	border-radius: 4px;
	width: 100%;
	margin-bottom: 0.5em;
    box-shadow: ${({type}) => type === "file" ? "none" : "0 0 10px rgba(92,204,156,0.25)"};
    -webkit-appearance: none;    
    
    &:focus {
        border: "none";

    }

`;

export const Message = styled.label`
	margin-bottom: 0.5em;
	color: ${({theme: { color:{orange}}}) => orange};
    display: block;
    font-size: 12px;
`;
