import React from 'react'
import { Container } from '../../globalStyle'
import { DecriptionParagraph, Heading, Image,TopLine, ImgWrapper, InfoSec, TextWrapper, ContainerTwo, ContainerInner } from './InfoSectionTwo.styled'

const InfoSectionTwo = () => {
  return (
    <InfoSec>
    <ContainerTwo>
        <ImgWrapper  >
                <Image src={"./assets/test1.jpg"} alt="" />
        </ImgWrapper>
            <TextWrapper >
                <Heading >...vision</Heading>
                <DecriptionParagraph >
                Our aim is to exceed your expectations... <br /> <br /> We are dedicated to bring the latest technologies to our customers and find the best solutions to digitally trandorm businesses. 
We are building the atmosphere which creates passionate teams that are always ready to go above and beyond to produce quality.   <br />
As far as we are moving the development proccesses foward we are abble to deliver the best solutions for our clients. 
                </DecriptionParagraph>
            </TextWrapper>
    </ContainerTwo>
    </InfoSec>
  )
}

export default InfoSectionTwo