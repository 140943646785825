import styled from "styled-components"
import { Container } from "../../globalStyle"
import { Link } from "react-router-dom"
import { HashLink } from 'react-router-hash-link';

// import { Link } from "react-scroll/modules"
import {FaMagento} from "react-icons/fa"

export const  NavbarStyled = styled.nav`
/* background-color: #fff; */
height: 80px;
display: flex;
align-items: center;
justify-content: center;
font-size: 1.2rem;
position: sticky;
top: 0;
z-index: 9;
-webkit-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px);


`
export const  NavbarContainer = styled(Container)`
display: flex;
justify-content: space-between;
height: 80px;

${Container}


`

export const  NavLogo = styled(Link)`
color: ${({theme: {color: {primary}}}) => primary};
justify-content: start;
align-items: center;
display: flex;
text-decoration: none;
font-size: 2rem;
`

export const  NavIcon = styled.img`
height: 50px;
width: 50px;
border-radius: 50%;
margin-right: 0.5rem;


`
export const  MobileIcon = styled.div`
display: none;


@media screen and (max-width: 992px) {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
    
}


`

export const  NavMenu = styled.ul`
display: flex;
justify-content: center;
align-items:center;

@media screen and (max-width: 992px) {
  flex-direction: column ;
  align-items: center;
  justify-content: flex-start;
  margin-top: 80px;
  height: 90vh;
  width: 100%;
  background-color: #5ccc9c;
  position: absolute;
  top: 0;
  left: ${({click}) => (!click ? "-100%" : 0)};
  transition: all ease 0.3s;

}

`

export const  NavItem = styled.div`
display: block;
margin-left: 2rem;

@media screen and (max-width: 992px) {
    margin-left: 0;
    padding: 1.8rem;
    width: 100%;}


`

export const  NavLink = styled(Link)`
  color: ${({exact, theme: {color: {textDark}}}) => exact? "#fff" : textDark};
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
  transition: ease 0.3s;

@media screen and (max-width: 992px) {
    justify-content: center;
    align-items: center;
}

&:hover {
    border-bottom: 1px solid #1b213636;
}
`

export const  NavHashLink = styled(HashLink)`
  color: ${({exact, theme: {color: {textDark}}}) => exact? "#fff" : textDark};
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
  transition: ease 0.3s;


@media screen and (max-width: 992px) {
    justify-content: center;
    align-items: center;
}

&:hover {
    border-bottom: ${({logo, theme: {color: {navLinks}}}) =>logo ? "none" :  '1px solid #1b213636'} ;
}

`




export const  NavItemButton = styled.li`
display: flex;
justify-content: center;
align-items:center;
width: 100%;
height: 120px;
`

export const  ButtonLink = styled(Link)`
color: #fff;
justify-content: start;
align-items: center;
display: flex;
text-decoration: none;
font-size: 1.8rem;
cursor: pointer;
`

