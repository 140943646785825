import React, {useState} from 'react'
import { NavbarStyled, NavbarContainer, NavLogo, NavIcon, MobileIcon, NavMenu, NavItem, NavLink, NavItemButton, ButtonLink, NavHashLink } from './Navbar.styled'
import {FaTimes, FaBars } from 'react-icons/fa'
import { Button } from '../../globalStyle'
import { useEffect } from 'react'
import logo from '../../assets/logo16.svg'


const Navbar = () => {
    const [click, setClick] = useState(false)
    const [button, setButton] = useState(true)
    const [isOnSection, setIsOnSection] = useState(false)

    const changeNavColor = () => {
        if(window.pageYOffset >= 2322 && window.pageYOffset < 2880){
            setIsOnSection(true)
        }else{
            setIsOnSection(false)
        }
    }

    const handleClick = () => {
        setClick(!click)
    }

    const showButton = () => {
        if(window.innerWidth <= 992){
            setButton(false)
        }else{
            setButton(true)
        }
    }

    useEffect(() => {
        showButton()
    }, [])

    window.addEventListener("resize", showButton)
    window.addEventListener("scroll", changeNavColor)


  return (
    <NavbarStyled>
        <NavbarContainer >
            <NavHashLink logo="true" smooth to="/#" >
                <NavIcon src={logo} alt='' />
            </NavHashLink>
            <MobileIcon onClick={handleClick} >{click? <FaTimes/> : <FaBars/> }</MobileIcon>
            <NavMenu  onClick={handleClick} click={click}>
                <NavItem>
                     <NavHashLink exact={isOnSection? 1 : 0}  smooth to="/#" >Home</NavHashLink>
                </NavItem>
                {/* <NavItem>
                    <NavHashLink  exact={isOnSection? 1 : 0} smooth to="/#about" >About</NavHashLink>
                </NavItem> */}
                <NavItem>
                    <NavHashLink  exact={isOnSection? 1 : 0} smooth to="/#services" >Services</NavHashLink>
                </NavItem>
                <NavItem>
                    <NavLink  exact={isOnSection? 1 : 0}  to="career/job_desc">Career</NavLink>
                </NavItem>
                <NavItem>
                    <NavHashLink  exact={isOnSection? 1 : 0} smooth to="contact-us-form" >Contact</NavHashLink>
                </NavItem>
                {/* <NavItem>
                    {button ? (
                    <ButtonLink to="/sign-up" >
                        <Button  >Join Us</Button>
                    </ButtonLink>

                    ) : 
                    (
                        <ButtonLink to="/sign-up" >
                        <Button bigFont primary >Join Us</Button>
                    </ButtonLink>

                    )}
                </NavItem> */}
            </NavMenu>
        </NavbarContainer>
    </NavbarStyled>
  )
}

export default Navbar