import styled from "styled-components";

export const  FormWrapper = styled.div`
/* width: 100%; */
max-width: 1000px;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
margin: 80px auto;
padding: 80px 40px;
text-align: center;
box-shadow: ${({type}) => type === "file" ? "none" : "0 0 10px rgba(0,0,0,0.15)"};
    -webkit-appearance: none;    
 h1{
     color: ${({theme}) => theme.color.textDark};
     margin-bottom: 40px;
 }

 @media (max-width: 992px) {
        display: flex;
        flex-direction: column;
        margin: 0px 10px 20px 10px;
        padding: 40px 40px;

        
    }

`

export const Forms = styled.form`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    height: 476px;
    width: 100%;
    margin: 0 auto;
    /* padding: 20px; */


    @media screen and (max-width: 992px) {
        height: fit-content;
        

        
    }

`


export const FormGroup = styled.div`
    text-align: start;
	color: ${({theme: { color:{primary}}}) => primary};
    display: block;
	width: 300px;
	margin: 10px auto;
    position: relative;
    margin-bottom: ${({type}) => type === "file" && "4.5em"};

    button{
        position: absolute;
        right:  0;
        left: ${({type}) => type === "file" && 0};
    }
`;

export const Label = styled.label`
	margin-bottom: 0.5em;
	color: ${({theme: { color:{textDark}}}) => textDark};
    display: block;
    margin-bottom: 4px;
    font-size: 12px;
`;


export const Input = styled.input`
	padding: ${({type}) => type !== "file" ? "0.8em" : "1em 0"};
    color: ${({type, theme: {color}}) => type !== "file" ? "#333" : color.primary};
	background:  #fff;
	border: none;
	border-radius: 4px;
	width: 100%;
	margin-bottom: 0.5em;
    box-shadow: ${({type}) => type === "file" ? "none" : "0 0 10px rgba(92,204,156,0.25)"};
    -webkit-appearance: none;    
    
    &:focus {
        border: "none";

    }

`;

export const Message = styled.label`
	margin-bottom: 0.5em;
	color: ${({theme: { color:{orange}}}) => orange};
    display: block;
    font-size: 12px;
`;
