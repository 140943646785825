import React from 'react'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, Container, theme } from '../../globalStyle'
import Modal from '../Modal/Modal'
import { FormGroup, Forms, FormSection, FormWrapper, InfoBanner, Input,InputTextArea, Label, Message } from './ContactUs.styled'
import {HiOutlinePhone} from "react-icons/hi"
import {GoLocation, GoMail} from "react-icons/go"
import axios from 'axios'


const formData = [
  {id: 0, label : "Full Name", message: "", name: "fullName", value: "" , type: "text"},
  {id: 1, label : "Company Name", message: "* required", name: "company_name", value: "" , type: "text"},
  {id: 2, label : "Email", message: "* required", name: "email", value: "", type: "email"},
  {id: 3, label : "Phone", message: "", name: "phone", value: "" , type: "tel"},
  {id: 4, label : "Message", message: "", name: "message", value: "", type: "textarea"},

]

const userObj = {
  file: null,
  fullName: "",
  email: "",
  phone: "",
  link_linkedIn: "",
  link_Git: "",
  company_name: "",
  message: ""
}


const ContactUs = () =>{  
  const [user, setUsertCredentals] = useState(userObj)
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const [active, setActive] = useState(false)
  const hiddenFileInput = React.useRef(null);
  const navigate = useNavigate();
  const blue = theme.color.blue

  const handleChange = (e) => {
    e.persist()
    console.log({[ e.target.name] : e.target.value});
    setUsertCredentals(() => ({...user ,[ e.target.name] : e.target.value}))

  }

  const handleSubmit = (e) => {
    console.log("USER");
    setLoading(true)

    const data = new FormData();
    data.append("file", user.file);
    data.append("fullName", user.fullName);
    data.append("email", user.email);
    data.append("phone", user.phone);
    data.append("link_linkedIn", user.link_linkedIn);
    data.append("link_Git", user.link_Git);
    data.append("company_name", user.company_name);
    data.append("message", user.message);
    axios({
      method: "post",
      url: "https://email-api-smtph-r.herokuapp.com/api/sendemail",
      data: data,
      headers: { "Content-Type": "multipart/form-data",
    },
    })
      .then(function (response) {
        setLoading(false)
        navigate("/career/success", {state : {from: "contact"}})
      })
      .catch(function (error) {
        setLoading(false)        
        inputValidator({...error.response.data.failure})
      });
   
    e.preventDefault()
  
  }

  const inputValidator = (input) => {
    if(!input.email || !input.company_name ){
      setActive(true)
      setErrorMessage(" * required field !")
    }else{
      setErrorMessage(" Something went wrong. Try Again")
    }
  }

  
  return(
    <Container>
      <FormSection>
        <InfoBanner>
          <ul>
            <li>
              <GoLocation color={blue} size={30} />
              <div>
              <h3>Address</h3> 
              <br /> 
              <p>ul Gjuro Salaj 1, Gjorce Petrov <br /> 1000 Skopje North Macedonia</p>
              </div>
            </li>
            <li> 
            <HiOutlinePhone color={blue} size={30} />
            <div>
              <h3>Lets Talk</h3> 
              <br /> 
              <p>+ 389 75 276 220 (WhatsUp)</p>
            </div>
            </li>
            <li>
            <GoMail color={blue} size={30} />
            <div>
            <h3>General Support</h3> 
              <br /> 
              <p>info@meta-o5.mk</p>
              </div>
            </li>
          </ul>
        </InfoBanner>
        <FormWrapper>
        <h1>Send Us A Message</h1>
       <Forms onSubmit={handleSubmit} action='https://email-api-smtph-r.herokuapp.com/api/sendemail' method='POST' enctype='multipart/form-data' >
       {formData.map(item => (
           <FormGroup key={item.id} >
           <Label htmlFor={item.type}>{item.label}</Label>
          
           {item.name === "message" ?  
          <InputTextArea
          ref={hiddenFileInput}
          type={item.type} 
          value={user[item.value]}  
          name={item.name} 
          onChange={handleChange} id="label" 
          />  : 
          <Input 
           ref={hiddenFileInput}
           type={item.type} 
           value={user[item.value]}  
           name={item.name} 
           onChange={handleChange} id="label" />}
           
           <Message>{item.message}</Message>
         </FormGroup>
       ))}
        <FormGroup >
            <Button type='submit' primary >Send {loading && <i className="fa fa-spinner fa-spin"></i>}</Button>
         </FormGroup>
       </Forms>
       <Modal
          active={active}
          hideModal={() => setActive(false)}>{errorMessage}</Modal>
        </FormWrapper>
      </FormSection>
    </Container>
  )
  
}

export default  ContactUs
  
