import React from 'react'
import { useState } from 'react'
import { Container } from 'react-bootstrap'
import { Button } from '../../globalStyle'
import { FormWrapper, Forms, FormGroup, Label, Input, Message } from './ApplyForm.styled'
import axios from 'axios';
import { useNavigate } from 'react-router-dom'
import {FaSpinner} from 'react-icons/fa'
import Modal from '../Modal/Modal'


const formData = [
  {id: 0, label : "Full Name", message: "", name: "fullName", value: "" , type: "text"},
  {id: 1, label : "Email", message: "* required", name: "email", value: "", type: "email"},
  {id: 2, label : "Phone", message: "", name: "phone", value: "" , type: "number"},
  {id: 3, label : "Linked In", message: "", name: "link_linkedIn", value: "", type: "text"},
  {id: 4, label : "Repository url:", message: "", name: "link_Git", value: "", type: "text"},
  {id: 5, label : "Upload CV_(pdf)", message: "* required", name: "file", value: "", type: "file"},
]


const userObj = {
  file: null,
  fullName: "",
  email: "",
  phone: "",
  link_linkedIn: "",
  link_Git: "",
  company_name: "",
  message: ""
}

const ApplyFormSection = ({handleSelectdItem}) =>{  
  const [user, setUsertCredentals] = useState(userObj)
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const [active, setActive] = useState(false)
  const hiddenFileInput = React.useRef(null);
  const navigate = useNavigate();


  const handleChange = (e) => {
    e.persist()
    setUsertCredentals(() => ({...user ,[ e.target.name] : e.target.name === "file" ?   e.target.files[0] : e.target.value}))

  }
  
  const handleSubmit = (e) => {
    console.log("USER", user);
    setLoading(true)

    const data = new FormData();
    data.append("file", user.file);
    data.append("fullName", user.fullName);
    data.append("email", user.email);
    data.append("phone", user.phone);
    data.append("link_linkedIn", user.link_linkedIn);
    data.append("link_Git", user.link_Git);
    axios({
      method: "post",
      url: "https://email-api-smtph-r.herokuapp.com/api/sendemail",
      data: data,
      headers: { "Content-Type": "multipart/form-data",
    },
    })
      .then(function (response) {
        setLoading(false)
        navigate("/career/success",  {state : {from: ""}})
      })
      .catch(function (error) {
        setLoading(false)
        inputValidator({...error.response.data.failure})
      });
    e.preventDefault()
  
  }

  const inputValidator = (input) => {
    console.log(!input.fullName);
    if(!input.email || !input.file){
      setActive(true)
      setErrorMessage(" * required field !")
    }else{
      setErrorMessage(" Something went wrong. Try Again")
    }
  }


  return(
   <FormWrapper>
       <h1>Submit your Application</h1>
       <Forms onSubmit={handleSubmit} action='https://email-api-smtph-r.herokuapp.com/api/sendemail' method='POST' enctype='multipart/form-data' >
       {formData.map(item => (
           <FormGroup key={item.id} >
           <Label htmlFor={item.type}>{item.label}</Label>
           <Input 
           ref={hiddenFileInput}
           type={item.type} 
           value={user[item.value]}  
           name={item.name} 
           onChange={handleChange} id="label" />
           <Message>{item.message}</Message>
         </FormGroup>
       ))}
        <FormGroup >
            <Button type='submit' primary >Submit {loading && <i className="fa fa-spinner fa-spin"></i>}</Button>
         </FormGroup>
       </Forms>
       <Modal
          active={active}
          hideModal={() => setActive(false)}>{errorMessage}</Modal>
   </FormWrapper>
  )
}

export default  ApplyFormSection
  
