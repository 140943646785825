
// HOME PAGE
export const homeObjOne = {
  id: "hero",

        hero : true,
        imageBg: true,
        primary: false,
        lightBg: false,
        gradient: false,
        lightBgSec: false,
        lightTopLine: true,
        lightText: false,
        lightTextDesc: false,
        topLine: 'We create growth',
        headline: 'Giving you the tech with endless boundaries',
        description:
          'Our company is dedicated to implementing the highest quality state of the art software solutions.\n We are enforcing the best performance practices, while offering real scalability and maintainability to our solutions, delivered with trust, care and understanding.',
        buttonLabel: 'Join Us',
        reverseImage: false,
        img: '',
        alt: 'Credit Card',
        start: '',
        marginMin: true
}

export const homeObjTwo = {
  id: "homeTwo",

  hero : false,
  imageBg: false,

  primary: true,
  lightBg: false,
  gradient: false,
  lightTopLine: true,
  lightText: true,
  lightTextDesc: true,
  topLine: 'Instant Setup',
  headline: 'Extremely quick onboarding process',
  description:
    "Once you've joined, our team of specialist will reach out to you and get you set up in minutes.",
  buttonLabel: 'Learn More',
  reverseImage: true,
  img: './assets/test1.jpg',
  alt: 'Vault',
  start: '',
  marginMin: false


};

export const homeObjThree = {
  id: "about",

  hero : false,
  imageBg: false,

  primary: false,
  lightBg: true,
  gradient: false,
  lightTopLine: false,
  lightText: false,
  lightTextDesc: false,
  topLine: 'We know the way',
  headline:
    'Accelerate your digital transformation with us!',
  description:
    "Remember that just as digital transformations are about business first, and digital second, problems with your business data may be signals to look more closely at how your company is doing business generally. \nThe point of hiring us to craft your digital transformation strategy is to draw upon our expertise. We bring something to the table that you don’t have — experience and industry expertise across many different clients.",
  buttonLabel: 'View Case Study',
  reverseImage: true,
  img: './assets/988.jpg',
  alt: 'Vault',
  start: 'true',
  marginMin: false


};

export const homeObjFour = {
  id: "homeObjFour",
  hero : false,
  imageBg: true,
  primary: false,
  lightBg: false,
  gradient: true,
  lightTopLine: true,
  lightText: true,
  lightTextDesc: true,
  topLine: 'Inspiring environment',
  headline: 'A great place to grow',
  description:
    'Our team is always excited about meeting passionate talents that want to grow with us! We are welcoming inspiring and creative individuals to expand our strong team.',
  buttonLabel: 'Join Our Team',
  reverseImage: false,
  img: './assets/metaHero.jpeg',
  alt: 'Vault',
  start: 'true',
  marginMin: false,
  blueGradient: true,



};

export const homeObjFive= {
  // id: "homeObjFive",
  id: "services",
  hero : false,
  imageBg: false,
  primary: false,
  lightBg: true,
  gradient: false,
  lightTopLine: false,
  lightText: false,
  lightTextDesc: false,
  topLine: 'Sarah Jeni',
  headline:
    'We build bridges to connect your data, employees, and customers!',
  description:
    "You don’t have to create your digital transformation roadmap alone.",
  buttonLabel: 'View Case Study',
  reverseImage: true,
  img1: './assets/14.jpg',
  img2: './assets/14.jpg',
  img3: './assets/14.jpg',
  alt: 'Vault',
  last: true,
  career: false,
  start: 'true',
  marginMin: false


};

// Career
export const CareerObjOne = {
        id: "#JoinUs",
        career: false,
        hero : false,
        imageBg: false,
        primary: true,
        lightBg: false,
        gradient: false,
        gradientImage: true,
        lightBgSec: false,
        lightTopLine: true,
        lightText: false,
        lightTextDesc: false,
        topLine: '',
        headline: 'Join Us!',
        description:
          'Join us in a friendly and divers environment alongside the most talented people in the field! \n\nHere at Meta5Team, we are eager to work with people who have the drive to be the best. We would like you to show your full potential, execute your ideas and be creative in building your future. \n\nChange your future with us!',
        buttonLabel: '',
        reverseImage: false,
        img: './assets/svg-1.svg',
        alt: '',
        start: '',
        marginMin: true

};

export const CareerObjTwo = {
  id: "appply",
  hero : false,
  imageBg: false,
  career: true,
  primary: false,
  lightBg: true,
  gradient: false,
  lightTopLine: false,
  lightText: false,
  lightTextDesc: false,
  topLine: 'Sarah Jeni',
  headline:
    'Ultra helped me increase my revenue by over 3X in less than 3 months!',
  description:
    "Their team is wonderful! I can't believe I didn't start working with them earlier.",
  buttonLabel: 'Apply',
  reverseImage: true,
  img: './assets/14.jpg',
  alt: 'Vault',
  last: false,
  career: true,
  start: 'true',
  marginMin: false

}

export const  cardData = [
  {
    id:0,
    title: "Software Solutions",
    subtitile: "Digital Transformation",
    description: "We offer software solutions that can be great because we have the depth of experience and knowledge to help you figure out the best paths to success.",
    img: "./assets/977.jpg"
  

},

{
  id:1,
  title: "Consultancy",
  subtitile: "Tehnical Expertise",
  description: "Choosing us, the right partner to consult on your transformation strategy lets you come up with a better plan, while also letting you stay focused on your core business.",
  img: "./assets/966.jpg"


}


]



