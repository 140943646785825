import React from 'react'
import { Container, FlexRow } from '../../globalStyle'
import { FooterStyled, AllRightsWrapper } from './Footer.styled'
import SocialIcons from './SocialIcons'
import logo from '../../assets/logo48.svg'

const Footer = () => {
  return (
    <FooterStyled>
        <Container nav textAlign="center">
            <img height={80} width={80} src={logo} alt="" />
            <FlexRow>
                <ul>
                <li>
                Meta5Team
                </li>
                <li><a href="/contact-us-form">Contact</a></li>
                <li>info@meta-o5.mk</li>
                <li>+ 389 75 276220</li>
            </ul>
            <ul>
                <br /><br />
                <li><a href="/">Home</a></li>
                <li><a href="/career/job_desc">Career</a></li>
            </ul>
            <SocialIcons/>
            </FlexRow>
            <AllRightsWrapper>
            <p>&copy; {new Date().getFullYear()} Meta5Team. All rights reserved.</p>
            </AllRightsWrapper>
        </Container>
        
    </FooterStyled>
  )
}

export default Footer